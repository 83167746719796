@import "../node_modules/reset-css/reset.css";

/*
  Fonts
 */
@font-face {
    font-family: 'Goatling';
    src: url('/fonts/Goatling/Goatling.woff') format('woff'),
    url('/fonts/Goatling/Goatling.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Journal Sans New';
    src: url('/fonts/subset-JournalSansNew-Bold.woff2') format('woff2'),
    url('/fonts/subset-JournalSansNew-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Journal Sans New';
    src: url('/fonts/JournalSansNew.woff2') format('woff2'),
    url('/fonts/JournalSansNew.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Journal';
    src: url('/fonts/Journal-Italic.woff2') format('woff2'),
    url('/fonts/Journal-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

html, body {
    height: 100%;
}

html {
    color: #fff;
    font-family: "Journal Sans New";
}

.page {
    background: url(/img/bg.png) no-repeat;
    background-size: cover;
    background-position: 0 0;
    position: relative;

    width: 100%;
    min-height: 100%;

    display: flex;
    align-items: flex-end;
}

.page:after, .page:before {
    content: '';
    display: block;

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 0;

    /*background-blend-mode: multiply;*/

    /*z-index: -1;*/

    opacity: 0.8;
}

.page:before {
    width: 100%;
    height: 100%;

    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #000 100%);
}

.page:after {
    height: 100%;
    width: 100%;

    background: linear-gradient(248.49deg, rgba(196, 196, 196, 0) 32.15%, #000 89.12%);
}

.container {
    width: 312px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 60px 0;
    overflow-x: hidden;
}

.logo {
    margin-bottom: 12px;
}

.presents {
    font-family: Journal;
    font-style: italic;
    background: url(/img/icon-tilda.svg) 0 100% no-repeat;
    padding-bottom: 24px;
    margin-bottom: 26px;
    font-size: 14px;
}

.heading {
    font-family: "Goatling";
    text-transform: uppercase;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.02em;
    margin-bottom: 22px;
}

.subheading {
    font-family: "Journal Sans New";
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 26px;
}

.links {
    display: flex;
}

.link {
    margin-bottom: 12px;
    padding-right: 8px;
}

.link:last-child {
    margin-bottom: 0;
}

.link img {
    max-width: 130px;
}

.swiper-container {
    width: calc(100% + 114px);
    height: 164px;
    margin-bottom: 22px;
    position: relative;
    left: -114px;
}

.swiper-slide {
    width: 198px;
    height: 132px;

    border-radius: 12px;
    overflow: hidden;
}

.swiper-slide img {
    max-width: 100%;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 0;
    left: -54px;
}

.swiper-pagination-bullet-active {
    background-color: #fff;
}

.swiper-pagination-bullet {
    background-color: #fff;
}

.slide-title {
    position: absolute;
    bottom: 8px;
    left: 6px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.8);
    height: 21px;
    display: flex;
    align-items: center;
    padding: 0 7px;
    color: #5E5E5E;
    font-size: 10px;
    text-transform: uppercase;
}

.glightbox-open {
    height: 100% !important;
}

.glightbox-clean .gslide-title, .glightbox-modern .gslide-title {
    font-family: "Journal Sans New";
    text-align: center;
    margin-bottom: 0;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}
